import axios from "axios";
import { __getItemFromLoacalStorage } from "../utils/Utility";
import { baseUrl } from "../constants/config";


export const _getUserProfile = async (payload: any) => {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${__getItemFromLoacalStorage("_token")}`,
    };
    let data = JSON.stringify(payload);
  
    try {
      const chatResponse = await axios({
        method: `post`,
        url: `${baseUrl}/user-profile`,
        data,
        headers,
      });
      return chatResponse;
    } catch (err) {
      console.log({ err });
    }
  };