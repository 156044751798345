import "./style.scss";

const OptionMenu = ({
  menuOptions,
  callBack,
}: {
  menuOptions: any[];
  callBack: Function;
}) => {
  return (
    <>
      <div className="option-menu-con">
        {menuOptions.map((option: any) => (
          <div className="menu-title-con" onClick={callBack()}>
            <div className="h4-white">{option.title}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default OptionMenu;
