import "./style.scss";
import { ApplicationCtx } from "../../../contexts/ApplicationCtx";
import { useContext, useEffect, useState } from "react";
import SearchIcon from "../../../assets/icons/uil_search.svg";
import { _deleteChat, _getChatHistoryByUserId } from "../../../network/chat";
import ConfirmationModal from "../../shared/confirmatioModal/ConfirmationModal";
import { createSearchParams, useNavigate } from "react-router-dom";
import { __isSameDay, __isToday, __isYesterday } from "../../../utils/Utility";

const AiHistory = () => {
  const { isSideNavOpen, setIsSideNavOpen, showStopResponding, setUrl } =
    useContext(ApplicationCtx);
  const [chatHistory, setChatHistory] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState<any>();
  const [currentList, setCurrentList] = useState<any[]>([]);
  const [prevDate, setPrevDate] = useState<Date>(new Date());
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState(1);
  const [page, setpage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let url = window.location.href;
    setUrl(url);
    setIsSideNavOpen(true);
    loadChatHistory();
  }, []);

  const loadChatHistory = () => {
    setIsLoading(true);
    _getChatHistoryByUserId(userId, page)
      .then((res: any) => {
        if (res.status === 200) {
          let data = res.data.chat_history;
          let pagination = res.data.pagination;
          setDataPerPage(pagination.per_page);
          setTotalRecords(pagination.total_rows);
          setChatHistory((prevState: any[]) => [...prevState, ...data]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const handleYes = () => {
    let chatId = selectedChat.chat_id;
    _deleteChat(chatId)
      .then((res: any) => {
        setIsModalOpen(false);
      })
      .then((res: any) => {
        loadChatHistory();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const handleNo = () => {
    setIsModalOpen(false);
  };

  const openChat = (chat: any) => {
    navigate({
      pathname: "/chats",
      search: createSearchParams({
        id: chat.chat_id,
      }).toString(),
    });
  };

  useEffect(() => {
    if (page > 1) {
      loadChatHistory();
    }
  }, [page]);

  const handleScroll = (e: any) => {
    console.log({ e });

    let bottomOffset: any = document.getElementById("bottompart")?.offsetTop;

    if (totalRecords && page * dataPerPage < totalRecords) {
      setpage((prevState: any) => {
        return prevState + 1;
      });
    }

    console.log({ bottomOffset });
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal-con">
          <ConfirmationModal handleNo={handleNo} handleYes={handleYes} />
        </div>
      )}
      <div className="history-container">
        <div className="chat-input-con">
          <input className="chat-input-text" type="text" />
          <img src={SearchIcon} />
        </div>
        <>
          <div className="history-list-con" onScroll={handleScroll}>
            {!isLoading &&
              chatHistory.map((chat: any, index: number) => (
                <>
                  {((index > 0 &&
                    !__isSameDay(
                      new Date(chatHistory[index - 1].updated_at),
                      new Date(chatHistory[index].updated_at)
                    )) ||
                    index == 0) && (
                    <div className="day" key={index}>
                      {__isToday(new Date(chat.updated_at))
                        ? "Today"
                        : __isYesterday(new Date(chat?.updated_at))
                        ? "Yesterday"
                        : `${new Date(chat?.updated_at).toDateString()}`}
                    </div>
                  )}
                  <div
                    className={`${
                      index % 2 == 0 ? "lgb" : "dgb"
                    }  "history-content"`}
                    key={chat.chat_id}
                  >
                    <div className="question-container">
                      <div className="history-text">
                        <div
                          className="h4-white"
                          onClick={() => openChat(chat)}
                        >
                          {chat.title}
                        </div>
                      </div>
                      <div
                        className="check-primary"
                        onClick={() => {
                          setIsModalOpen(true);
                          setSelectedChat(chat);
                        }}
                      >
                        <input type="checkbox" id="check"></input>
                      </div>
                    </div>
                  </div>
                  {/* {setPrevDate(chat.updated_at)} */}
                </>
              ))}
          </div>
        </>
        <div id="bottompart"></div>
      </div>
    </>
  );
};
export default AiHistory;
