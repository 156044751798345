import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { sidepanelMenuOptions } from "./constants";
import "./style.scss";
import { ApplicationCtx } from "../../../contexts/ApplicationCtx";
import { useAuth0 } from "@auth0/auth0-react";

const Sidepanel = () => {
  const navigate = useNavigate();

  const [selectedMenu, setSelectedMenu] = useState(null);
  const [menuOptioin, setMenuOption] = useState(sidepanelMenuOptions);
  const { url } = useContext(ApplicationCtx);
  const { logout } = useAuth0();

  useEffect(() => {
    setMenuOption((prevState: any) => {
      return prevState.map((option: any) => {
        if (url.indexOf(option.path) != -1) {
          return {
            ...option,
            active: true,
          };
        }
        return {
          ...option,
          active: false,
        };
      });
    });
  }, [url]);

  const doNavigation = (menu: any) => {
    if (menu.key === "logout") {
      return logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
    navigate(menu.path);
    setSelectedMenu(menu);
  };

  return (
    <>
      <div className="sidepanel-container">
        {menuOptioin.map((menu: any, index: any) => (
          <div
            key={index}
            className="menu-con"
            onClick={() => doNavigation(menu)}
          >
            {menu.active ? (
              <div className="border-left"></div>
            ) : (
              <div className="border-blank"></div>
            )}
            <div className="menu-icon-con">{menu.icon}</div>
            <div className="h2-white">{menu.title}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Sidepanel;
