import { useContext, useEffect } from "react";
import { ApplicationCtx } from "../../../contexts/ApplicationCtx";
import Crown from "../../../assets/icons/LeaderBoard-icons/crown.svg";
import Profile from "../../../assets/icons/LeaderBoard-icons/profile-pic.svg";
import { userList } from "./constants";
import Diamond from "../../../assets/icons/LeaderBoard-icons/diamond.svg";

const LeaderboardList = () => {
  const { setIsSideNavOpen } = useContext(ApplicationCtx);
  useEffect(() => {
    setIsSideNavOpen(true);
  }, []);

  return (
    <>
      <div className="list-main-con">
        <div className="leaderboard-list-con">
          <div className="h1-white">Leaderboard</div>
          <div className="week-perks-con">
            <div className="crown-con">
              <img src={Crown} className="crown-image" />
            </div>
            <div className="text-con">
              <div className="h2-white">This Week's Perks</div>
              <div className="h4-white">Announced in 4 days</div>
            </div>
            <div className="diamond-con">
              <div className="diamond-icon">
                <img src={Diamond} />
              </div>
              <div className="diamond-value-con">550</div>
            </div>
          </div>
          <div className="rank-table-con">
            <div className="table-header-con">
              <div className="rank-con">Rank</div>
              <div className="name-con">Name</div>
              <div className="credit-con">Credits</div>
            </div>
            {userList.map((item, index) => {
              return (
                <div className="table-data-con">
                  <div className="ranks">{item.rank}</div>
                  <div className="profile-con">
                    <div className="profile-pic-con">
                      <img src={Profile} />
                      <div className="name-rank">
                        <div className="name">{item.name}</div>
                        <div className="platinum-con h4-white">
                          {item.membership}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="credits">{item.credits}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="your-rank-con">
          <div className="h1-white">Your Rank</div>
          <div className="black-card-con mt-60">
            <div className="profile-details-con">
              <div className="rank-con">1</div>
              <div className="profile-con">
                <div className="profile-pic-con">
                  <img src={Profile} />
                </div>
                <div className="user-member-con">
                  <div className="username-con">User_name</div>
                  <div className="membership-con">Platinum</div>
                </div>
              </div>
              <div className="credits-con">9000</div>
            </div>
          </div>
          <div className="learning-con">
            <div className="h4-white">
              Stand A Chance to win this week's perks
            </div>
            <div className="keep-learning-con mt-20">
              <button className="button-63">Keep learning</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaderboardList;
