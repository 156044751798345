import { createSearchParams, useNavigate } from "react-router-dom";

const ChatCard = ({ title, subtitle }: { title: any; subtitle: any }) => {
  function truncate(string: any, length: any) {
    if (string.length > length) return string.substring(0, length) + "...";
    else return string;
  }

  // const navigate = useNavigate();

  // const hadleClick = () => {
  //   navigate({
  //     pathname: "/chats",
  //     search: createSearchParams({
  //       chat: title,
  //     }).toString(),
  //   });
  // };

  return (
    <>
      <div className="chat-card-con">
        <div className="h2-white">{title}</div>
        <div className="h2-subtext mt-8">{truncate(subtitle, 20)}</div>
      </div>
    </>
  );
};

export default ChatCard;
