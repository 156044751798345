import "./style.scss";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomeImage from "../../../assets/icons/image_363.svg";
import FingerImage from "../../../assets/icons/fingerprint-2872266-2389477 (1).svg";
import CGLogo from "../../../assets/icons/cg-ai-icon.svg";
import { ApplicationCtx } from "../../../contexts/ApplicationCtx";
import { _getUserProfile } from "../../../network/user";

const Home = () => {
  const navigate = useNavigate();
  const handleGetStartedClick = () => {
    navigate("/landing");
  };
  const { isSideNavOpen, setIsSideNavOpen, setUrl, cgUser } =
    useContext(ApplicationCtx);

  useEffect(() => {
    let url = window.location.href;
    setUrl(url);
    setIsSideNavOpen(false);
  }, []);

  useEffect(() => {
    if (cgUser) {
      loadUserProfile();
    }
  }, [cgUser]);

  const loadUserProfile = () => {
    let payload = {
      email: cgUser.email,
    };
    _getUserProfile(payload).then((res: any) => {
      console.log({ res });
      let data = res.data;
      if (data?.paid_user) {
        navigate("/home");
      } else {
        window.location.replace(
          "https://whop.com/checkout/plan_hDC8O4TQ6Luwf?d2c=true"
        );
      }
    });
  };

  return (
    <>
      <div className="frame">
        <div className="content">
          <div className="first-con">
            <div className="ai-con">
              <div className="elements-con-1 float-up">
                <img src={CGLogo} />
                Elements from AI
              </div>
              <div className="elements-con-2 float-up">
                <img src={CGLogo} />
                Elements from AI
              </div>
            </div>
            <div className="home-image-con">
              <img src={HomeImage} className="home-image floating" />
            </div>
            <div className="finger-con">
              <img src={FingerImage} className="finger-image" />
            </div>
          </div>
          <div className="second-con">
            <div className="welcome-con">
              <h1 className="h1-white">
                Welcome to <br />
                CryptogradAI
              </h1>
              <h4 className="h4-white para">
                Empowering the crypto community with expert-curated crypto
                knowledge
              </h4>
              <button
                className="button-63 mobile-btn"
                onClick={handleGetStartedClick}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
