import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  sidepanelMenuOptions,
  mobileSidePanelMenuOptions,
} from "../../sidepanel/constants";
import "./style.scss";
import { ApplicationCtx } from "../../../../contexts/ApplicationCtx";
import { useAuth0 } from "@auth0/auth0-react";

const MobileSidePanel = () => {
  const navigate = useNavigate();
  const [isMenuClicked, setMenuClicked] = useState(false);
  const { logout, getAccessTokenSilently } = useAuth0();

  const doNavigation = (menu: any) => {
    if (menu.key === "logout") {
      return logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
    navigate(menu.path);
    setMenuClicked(!isMenuClicked);
  };

  return (
    <>
      <div className="overlay-container">
        <div className="mobile-panel-container">
          {mobileSidePanelMenuOptions.map((menu: any, index: any) => (
            <div
              key={index}
              className="menu-con"
              onClick={() => doNavigation(menu)}
            >
              <div className="menu-icon-con">{menu.icon}</div>
              <div className="h2-white">{menu.title}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MobileSidePanel;
