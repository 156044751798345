import { ApplicationCtx } from "../../../contexts/ApplicationCtx";
import "./style.scss";
import Trophy from "../../../assets/icons/LeaderBoard-icons/trophy.svg";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Leaderboard = () => {
  const navigate = useNavigate();
  const { setIsSideNavOpen, setUrl } = useContext(ApplicationCtx);
  const handleGetStartedClick = () => {
    navigate("/leaderboard-list");
  };
  useEffect(() => {
    let url = window.location.href;
    setUrl(url);
    setIsSideNavOpen(true);
  }, []);

  return (
    <>
      <div className="leaderboard-con">
        <div className="trophy-main-con">
          <div className="trophy-con">
            <img src={Trophy} className="trophy-image" />
          </div>
          <div className="get-started-con">
            <div className="big-text">Leaderboard</div>
            <div className="small-text">
              Take A Look At How Far You've Come!
            </div>
            <div className="btn-con">
              <button className="button-63" onClick={handleGetStartedClick}>
                {" "}
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
