import { useContext, useEffect } from "react";
import { ApplicationCtx } from "../../../contexts/ApplicationCtx";
import "./style.scss";
import ShareLogo from "../../../assets/icons/uil_share-alt.svg";
import CGLogo from "../../../assets/icons/image_369.svg";
import CopyLogo from "../../../assets/icons/uil_copy.svg";
import BlueCGLogo from "../../../assets/icons/image_361.svg";
import LinkButton from "../../shared/buttons/LinkButton";
import CGWhite from "../../../assets/icons/cg-white.svg";

const Refer = () => {
  const { setIsSideNavOpen, setUrl } = useContext(ApplicationCtx);
  useEffect(() => {
    let url = window.location.href;
    setUrl(url);
    setIsSideNavOpen(true);
  }, []);
  return (
    <>
      <div className="refer-con">
        <div className="h1-white header">
          Refer a Friend, <br /> Earn upto{" "}
          <span style={{ color: "#918FFF" }}>500 CG Tokens</span>
        </div>
        <div className="sub-header h3-white mt-20">
          Shop, Swap, and Savor. It's All Possible with CG Coins!
        </div>
        <div className="referal-con mt-20">
          <div className="refer-code">
            <div className="referall-code">qH3TmQikN</div>
            <div className="copy-con">
              <img src={CopyLogo}></img>
            </div>
          </div>
          <div className="share-button">
            <button className="share">
              <img src={ShareLogo}></img>
            </button>
          </div>
        </div>
        <div className="cg-tokens-con">
          <div className="blue-logo">
            <img src={BlueCGLogo}></img>
          </div>
          <div className="tokens-con">
            <div className="token-text">CG Tokens</div>
            <div className="tokens h2-white">524</div>
          </div>
          <div className="redeem-con">
            <LinkButton title={"Redeem"} type={"h3-white"} />
          </div>
        </div>
        <div className="reward-journey mt-20 h2-white">Your Reward Journey</div>
        <div className="reward-con mt-20">
          {Array.from({ length: 2 }).map((_, index) => (
            <div className="refer-card" key={index}>
              <div className="logo-container">
                <div className="cg-logo-white">
                  <img src={CGWhite} />
                </div>
                <div className="token-count h2-white">5</div>
              </div>
              <div className="referal-count h2-white">1 Referral</div>
            </div>
          ))}
          {Array.from({ length: 4 }).map((_, index) => (
            <div className="refer-card-gray" key={index}>
              <div className="logo-container">
                <div className="cg-logo-white">
                  <img src={CGWhite} />
                </div>
                <div className="token-count h3-white">5</div>
              </div>
              <div className="referal-count h2-white">1 Referral</div>
            </div>
          ))}
        </div>

        <div className="my-referrals mt-50">
          <div className="heading h1-white">My Referrals</div>
          <div className="referral-con mt-20">
            {Array.from({ length: 4 }).map((_, index) => (
              <div className="my-referral-card" key={index}>
                <div className="round-con">
                  <img src={CGLogo} />
                </div>
                <div className="name-steps">
                  <div className="friend-name">Jones Sami</div>
                  <div className="steps-con h3-subtext">
                    2 steps away, remind to join{" "}
                  </div>
                </div>
                <div className="remind-link">
                  <LinkButton title={"Remind"} type={"h3-blue"} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Refer;
