import React, { useState } from "react";
import "./style.scss";
import CrossLogo from "../../../assets/icons/close-cross.svg";

const ConfirmationModal = ({
  handleNo,
  handleYes,
}: {
  handleNo: Function;
  handleYes: Function;
}) => {
  return (
    <div className={`modal is-open`}>
      <div className="cross" onClick={() => handleNo()}>
        <div className="cross-logo">
          <img src={CrossLogo} />
        </div>
      </div>
      <div className="modal-content">
        <h2>Are you sure?</h2>
        <button className="danger h4-white" onClick={() => handleYes()}>
          Yes, Delete
        </button>
        <button className="primary h4-white" onClick={() => handleNo()}>
          No, keep it
        </button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
