import { useAuth0 } from "@auth0/auth0-react";
import "./style.scss";
import React, { useContext, useEffect, useState } from "react";
import HomeImage from "../../../assets/icons/image_363.svg";
import FingerImage from "../../../assets/icons/fingerprint-2872266-2389477 (1).svg";
import CGLogo from "../../../assets/icons/cg-ai-icon.svg";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null); // Explicitly define the type
  const { isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } =
    useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [isAuthenticated]);

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/home",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  const handleLogin = async () => {
    const login = await loginWithRedirect({
      appState: {
        returnTo: "/home",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
    console.log({ login });
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Basic validation (you may want to add more validation)
    if (!email || !password) {
      setError("Please enter both email and password.");
      return;
    }

    // TODO: Implement authentication logic with your backend here
    // For this example, we'll just log the credentials
    console.log("Email:", email);
    console.log("Password:", password);

    // Clear form and error after submission
    setEmail("");
    setPassword("");
    setError(null);
  };

  return (
    <div className="login-container">
      <div className="frame">
        <div className="content">
          <div className="first-con">
            <div className="ai-con">
              <div className="elements-con-1 float-up">
                <img src={CGLogo} />
                Elements from AI
              </div>
              <div className="elements-con-2 float-up">
                <img src={CGLogo} />
                Elements from AI
              </div>
            </div>
            <div className="home-image-con">
              <img src={HomeImage} className="home-image floating" />
            </div>
            <div className="finger-con">
              <img src={FingerImage} className="finger-image" />
            </div>
          </div>
          <div className="second-con">
            <div className="welcome-con">
              <h1 className="h1-white">
                Welcome to <br />
                CryptogradAI
              </h1>
              <h4 className="h4-white para">
                Empowering the crypto community with expert-curated crypto
                knowledge
              </h4>
              {!isAuthenticated && (
                <>
                  <button className="button-63" onClick={handleLogin}>
                    Log In
                  </button>
                  <button className="button-63 mt-20" onClick={handleSignUp}>
                    Sign Up
                  </button>
                </>
              )}
              {/* {isAuthenticated && (
                <button className="button-63" onClick={handleLogout}>
                  Log Out
                </button>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
