import axios from "axios";
import { baseUrl, baseUrlv2 } from '../constants/config';
import { __getItemFromLoacalStorage } from "../utils/Utility";

export const _getChatHistoryByUserId = async (userId: any, page: any) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${__getItemFromLoacalStorage("_token")}`,
  };

  try {
    const chatResponse = await axios({
      method: "get",
      url: `${baseUrl}/chat-history?user_id=${userId}&page=${page}`,
      headers,
    });
    return chatResponse;
  } catch (err) {
    console.log({ err });
  }
};

export const _getChatByChatId = async (chatId: string) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${__getItemFromLoacalStorage("_token")}`,
  };
  try {
    const chatResponse = await axios({
      method: "get",
      url: `${baseUrl}/chat?chat_id=${chatId}`,
      headers,
    });
    return chatResponse;
  } catch (err) {
    console.log({ err });
  }
};

export const _createChat = async (payload: any) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${__getItemFromLoacalStorage("_token")}`,
  };
  let data = JSON.stringify(payload);

  try {
    const chatResponse = await axios({
			method: `post`,
			url: `${baseUrlv2}/chat`,
			data,
			headers,
		});
    return chatResponse;
  } catch (err) {
    console.log({ err });
  }
};

export const _deleteChat = async (chatId: number) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${__getItemFromLoacalStorage("_token")}`,
  };
  try {
    const chatResponse = await axios({
      method: `delete`,
      url: `${baseUrl}/chat?chat_id=${chatId}`,
      headers,
    });
    return chatResponse;
  } catch (err) {
    console.log({ err });
  }
};

export const _generatePrompt = async (payload: any) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${__getItemFromLoacalStorage("_token")}`,
  };

  let data = JSON.stringify(payload);
  try {
    const chatResponse = await axios({
      method: `post`,
      url: `${baseUrl}/prompt-generator`,
      data,
      headers,
    });
    return chatResponse;
  } catch (err) {
    console.log({ err });
  }
};

export const _getStream = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${__getItemFromLoacalStorage("_token")}`,
  };
  try {
    const streamResponse = await axios({
      method: `get`,
      url: `http://65.2.67.70:80/stream`,
      headers,
    });
    return streamResponse;
  } catch (err) {
    console.log({ err });
  }
};
