export const __isSameDay = (date1: Date, date2: Date) => {
  if (date1.toDateString() === date2.toDateString()) {
    return true;
  }
  return false;
  // if (
  //   date1.getDate() === date2.getDate() &&
  //   date1.getMonth() === date2.getMonth() &&
  //   date1.getFullYear() === date2.getFullYear()
  // ) {
  //   return true;
  // }
  // return false;
};

export const __isToday = (date: any) => {
  const today = new Date();
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return true;
  }
  return false;
};

export const __isYesterday = (date: any) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    return true;
  }
  return false;
};

export const __setItemInLocalStorage = (field: string, value: any) => {
  localStorage.setItem(field, value);
}

export const __getItemFromLoacalStorage = (field: string) => {
  return localStorage.getItem(field);
}

export const __removeItemFromLocalStorage = (field: string) => {
  localStorage.removeItem(field);
}

export const __clearLocalStorage = () => {
  localStorage.clear();
}
