import ChatHome from "./components/pages/chats/ChatHome";
import Home from "./components/pages/home/Home";
import Landing from "./components/pages/landing/Landing";
import WalletConnect from "./components/pages/walletconnected/walletconnected";
import AiHistory from "./components/pages/ai-history/AiHistory";
import Explore from "./components/pages/explore/Explore";
import Refer from "./components/pages/refer/Refer";
import Leaderboard from "./components/pages/leaderboard/Leaderboard";
import LeaderboardList from "./components/pages/leaderboard/LeaderboardList";
import Login from "./components/pages/auth/Login";

export const routes = [
  {
    path: "/home",
    component: <Home />,
  },
  {
    path: "/chats",
    component: <ChatHome />,
  },
  {
    path: "/landing",
    component: <Landing />,
  },
  {
    path: "/walletconnected",
    component: <WalletConnect />,
  },
  {
    path: "/ai-history",
    component: <AiHistory />,
  },
  {
    path: "/explore",
    component: <Explore />,
  },
  {
    path: "/refer",
    component: <Refer />,
  },
  {
    path: "/leaderboard",
    component: <Leaderboard />,
  },
  {
    path: "/leaderboard-list",
    component: <LeaderboardList />,
  },
  {
    path: "/",
    component: <Login />,
  },
];
