import { createContext } from "react";

export const ApplicationCtx = createContext<any>({
  sideNavOpen: null,
  setSideNavOpen: Function,
  showStopResponding: null,
  setShowStopResponding: Function,
  url: null,
  setUrl: Function,
  cgUser: null,
  setCgUser: Function,
  mobileMode: null,
  setMobileMode: Function,
  isMobileNavBarActive: null,
  setIsMobileNavBarActive: Function
});
