import LinkButton from "../../shared/buttons/LinkButton";
import CandleLogo from "../../../assets/icons/image_375.svg";
import TradingLogo from "../../../assets/icons/image_374.svg";
import ArrowLogo from "../../../assets/icons/image_370.svg";
import BigCandleLogo from "../../../assets/icons/image_376.svg";
import HomeLogo from "../../../assets/icons/image_363.svg";
import CGLogo from "../../../assets/icons/image_369.svg";

export const BLACK_CARD_OPTIONS = [
  {
    title: "Step 1",
    subtitle: "Learn the Art of Input Trade Data",
    active: true,
  },
  {
    title: "Step 2",
    subtitle: "Elevate your trade game with trend predictions",
    active: false,
  },
  {
    title: "Step 3",
    subtitle: "Unlock your full trading potential",
    active: false,
  },
];

export const QUICK_ACTIONS_OPTIONS = [
  {
    title: "Analyze My Trading View Chart",
    subtitle: "Upload a Photo 📈",
    background: "sky-background",
  },
  {
    title: "Summarise and analyse my trading track record",
    subtitle: "Add URL 🔗",
    background: "blue-background",
  },
  {
    title: "Predict the upcoming MACRO BTC trend",
    subtitle: `Get Started`,
    background: "pink-background",
  },
];

export const TRADING_STRATEGIES_OPTIONS = [
  {
    title: "Trade using Volume Profile Strategy",
    background: "sky-background",
    image: (
      <>
        <img src={CandleLogo} className="mt-20" />
      </>
    ),
  },
  {
    title: "Trade better with trends",
    background: "orange-background",
    image: (
      <>
        <img src={TradingLogo} className="mt-20" />
      </>
    ),
  },
  {
    title: "Trade better with trends",
    background: "pink-background",
    image: (
      <>
        <img src={TradingLogo} className="mt-20" />
      </>
    ),
  },
];

export const TRADING_STRATEGIES_BLACK_OPTIONS = [
  {
    title:
      "Share trading insights on the cryptocurrency macro market. Discuss recent price fluctuations, market sentiments...",
    button: <LinkButton title={"Get Started"} type={"h3-blue"} />,
    type: "Crypto",
  },
  {
    title:
      "Analyze the historical price chart of a popular cryptocurrency. Using technical analysis and historical data...",
    button: <LinkButton title={"Get Started"} type={"h3-blue"} />,
    type: "General",
  },
  {
    title: "Share the latest liquidation levels of BTC and ETH",
    button: <LinkButton title={"Get Started"} type={"h3-blue"} />,
    type: "Crypto",
  },
];

export const GRAY_CARD_OPTIONS = [
  {
    title: "Predict the key trends",
    subtitle:
      "Predict the key trends in the technology market for the next five years.",
    image: (
      <>
        <img src={ArrowLogo} style={{ width: "84px", height: "84px" }}></img>
      </>
    ),
    button: <LinkButton title={"Get Started"} type={"h3-blue"} />,
  },
  {
    title: "Perform a trendline analysis",
    subtitle: "Perform a trendline analysis on the price chart of Bitcoin. ",
    image: (
      <>
        <img
          src={BigCandleLogo}
          style={{ width: "84px", height: "84px" }}
        ></img>
      </>
    ),
    button: <LinkButton title={"Get Started"} type={"h3-blue"} />,
  },
  {
    title: "Refine and generate prompts using CG AI",
    subtitle: "Unlock endless possibilities with the CG Prompt Generator.",
    image: (
      <>
        <img src={CGLogo} style={{ width: "84px", height: "84px" }}></img>
      </>
    ),
    button: <LinkButton title={"Get Started"} type={"h3-blue"} />,
  },
];
