import { useEffect, useState } from "react";
import "./style.scss";
import { _generatePrompt } from "../../../network/chat";
import { log } from "console";

const PromptGenerator = () => {
  const [promptTextResponse, setPromtTextResponse] = useState<any>();
  const [promptInput, setPromptInput] = useState<string>("");
  const [promptText, setPromptText] = useState<string>("");

  useEffect(() => {
    loadPrompt();
  }, []);

  const loadPrompt = () => {
    let payload = {
      text: promptInput,
    };
    _generatePrompt(payload).then((res: any) => {
      setPromtTextResponse(res?.data?.prompt);
    });
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default Enter behavior (e.g., new line in a textarea)
      setPromptText(promptInput);
      setPromptInput("");
      loadPrompt();
    }
  };

  return (
    <>
      <div className="pg-con">
        <div className="h2-white">Prompt Generator</div>
        <div className="h2-subtext">{`What is best way of trading in crypto?`}</div>
        <div className="cg-tip-con">
          <div className="h4-blue">📌 Tip of the day</div>
          <div className="h2-subtext mt-20">{`Scalping is a very common trading strategy among day traders. It involves taking advantage of small price moves that happen on short time frames.`}</div>
        </div>
        <div className="textarea">
          <textarea
            placeholder="Write your prompt here"
            className="textarea-big"
            value={promptInput}
            onChange={(e) => {
              setPromptInput(e.target.value);
            }}
            onKeyDown={handleKeyPress}
          />
        </div>
        <div className="textarea">
          <textarea placeholder="Refined Prompt" value={promptTextResponse} />
          <div className="copy-btn"></div>
        </div>
      </div>
    </>
  );
};

export default PromptGenerator;
