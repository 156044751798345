import Icon from "../../shared/icon/Icon";
import { ReactComponent as OptionIcon } from "../../../assets/icons/option-button.svg";
import "./style.scss";
import { cardQuestions, chatCardOptions, dummyqnaList } from "./Constants";
import ChatCard from "./ChatCard";
import { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as PlayIcon } from "../../../assets/icons/paly-icon.svg";
import { ReactComponent as StopIcon } from "../../../assets/icons/stop-icon.svg";
import CGBackground from "../../../assets/icons/GroupCG.svg";
import ChatQNA from "./ChatQNA";
import { menuOptions } from "./Constants";
import OptionMenu from "../../shared/optionMenu/OptionMenu";
import { ApplicationCtx } from "../../../contexts/ApplicationCtx";
import ConfirmationModal from "../../shared/confirmatioModal/ConfirmationModal";
import { v4 as uuidv4 } from "uuid";
import {
  _createChat,
  _getChatByChatId,
  _getStream,
} from "../../../network/chat";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as CGAIIcon } from "../../../assets/icons/cg-ai-icon.svg";

const ChatHome = () => {
  const [chatInput, setChatInput] = useState<string>("");
  const [isOptionMenuClosed, setIsOptionMenuClosed] = useState(true);
  const {
    isSideNavOpen,
    setIsSideNavOpen,
    showStopResponding,
    setShowStopResponding,
    setUrl,
    mobileMode,
    setIsMobileNavBarActive,
  } = useContext(ApplicationCtx);
  const [inputActive, setInputActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [qnaList, setQnaList] = useState<any[]>(dummyqnaList);
  const [currentQNA, setCurrentQNA] = useState<any[]>([]);
  const [stopResponding, setStopResponding] = useState(false);
  const [currentLink, setCurrentLink] = useState("");
  const [showWelcome, setShowWelcome] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const [tradingBehaviour, setTradingBehaviour] = useState("balance");
  const [searcParams] = useSearchParams();
  const [currentChatId, setCurrentChatId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [chatReqBody, setChatReqBody] = useState<any>({
    user_id: 1,
    message: "",
  });
  const [onGoingStream, setOngoingStream] = useState(false);
  const [onGoingCard, setOngoingCard] = useState<"" | "Analysemytrade">("");
  const [displayResponse, setDisplayResponse] = useState("");
  const [from, setFrom] = useState("");
  const [chatIdState, setChatIdState] = useState("");
  const [newChat, setNewChat] = useState(true);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentQNA([]);
    setIsOptionMenuClosed(true);
  };

  useEffect(() => {
    let url = window.location.href;
    setUrl(url);
    setIsSideNavOpen(true);
    setIsMobileNavBarActive(false);
    let chatId = searcParams.get("id");
    if (chatId) {
      getChatHistory(chatId);
      setChatIdState(chatId);
      setNewChat(false);
    } else {
      setChatIdState(uuidv4());
    }
  }, []);

  useEffect(() => {
    setChatReqBody((prevState: any) => {
      return {
        ...prevState,
        message: chatInput,
      };
    });
  }, [chatInput]);

  useEffect(() => {
    setChatReqBody((prevState: any) => {
      return {
        ...prevState,
        chat_id: currentChatId,
      };
    });
  }, [currentChatId]);

  const getChatHistory = (chatId: string) => {
    _getChatByChatId(chatId)
      .then((res: any) => {
        let chatResponse = res.data;
        chatResponse.map((chat: any) => {
          if (chat.role === "user") {
            setCurrentQNA((prevState: any) => {
              return [...prevState, { ques: chat.text, ans: "" }];
            });
          } else if (chat.role === "assistant") {
            setCurrentQNA((prevState: any) => {
              return [...prevState, { ques: "", ans: chat.text }];
            });
          }
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  useEffect(() => {
    if (currentQNA.length) {
      setShowWelcome(false);
    } else {
      setShowWelcome(true);
    }
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [currentQNA]);
  const handleCardClick = (card: any) => {
    setFrom("card");
    setOngoingCard(card.title.replace(/\s/g, ""));
    setChatInput("");
    setChatReqBody((prevState: any) => {
      return {
        ...prevState,
        message: card.title,
      };
    });
  };

  // const

  useEffect(() => {
    if (from === "card") {
      handleSubmit();
    }
  }, [from]);

  const handleSubmit = () => {
    let cardResponse = "";
    if (onGoingCard) {
      if (
        cardQuestions[onGoingCard]?.length === Math.floor(currentQNA.length / 2)
      ) {
        setOngoingCard("");
        currentQNA.forEach((qna) => {
          if (qna.ques) {
            cardResponse += `${qna.ques}\n`;
          } else if (qna.ans) {
            cardResponse += `${qna.ans}=`;
          }
        });
        cardResponse += chatInput;
      } else {
        setCurrentQNA((prevState: any) => {
          return [...prevState, { ques: chatInput, ans: "" }];
        });
        setCurrentQNA((prevState: any) => {
          return [
            ...prevState,
            {
              ques: "",
              ans: cardQuestions[onGoingCard][
                Math.floor(currentQNA.length / 2)
              ],
            },
          ];
        });
        setChatInput("");
        return;
      }
    }
    setCurrentQNA((prevState: any) => {
      return [...prevState, { ques: chatInput, ans: "" }];
    });
    setShowStopResponding(true);
    setIsLoading(true);
    // const uuid = uuidv4();
    _createChat({
      ...chatReqBody,
      message: `${
        tradingBehaviour !== "balance"
          ? `trading behaviour=${tradingBehaviour}\n`
          : ""
      } ${cardResponse ? cardResponse : chatReqBody.message}`,
      new_chat: newChat,
      chat_id: chatIdState,
    })
      .then((res: any) => {
        if (res.status === 200) {
          // _createChat(chatReqBody);
          let data = res.data;
          setCurrentChatId(chatIdState);
          setNewChat(false);
          // let id = data.chat_id;
          // console.log({ id });

          // var eventSource = new EventSource('https://api.cryptograd.ai/stream');
          // eventSource.addEventListener(
          // 	uuid,
          // 	function (event) {
          // 		try {
          // 			console.log('event.data', event.data);
          // 			if (!event.data?.finish) {
          // 				if (!onGoingStream) setOngoingStream(true);
          // 				setDisplayResponse(
          // 					(old) => old + event.data?.chatbot_response
          // 				);
          // 			} else {
          // 				setOngoingStream(false);
          // 				setIsLoading(false);
          // 				setDisplayResponse((old) => {
          // 					setCurrentQNA((prevState: any) => {
          // 						return [...prevState, { ques: '', ans: old }];
          // 					});
          // 					return '';
          // 				});
          // 			}
          // 			console.log(event?.data);
          // 		} catch (err: any) {
          // 			console.log({ err });
          // 			setIsLoading(false);
          // 			setOngoingStream(false);
          // 		}
          // 	},
          // 	false
          // );

          setCurrentQNA((prevState: any) => {
            return [...prevState, { ques: "", ans: data.chatbot_response }];
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log({ err });
        // setIsLoading(false);
      });

    setChatInput("");
    resetTextareaHeight();
  };

  const handleKeyPress = (e: any) => {
    // Check if the pressed key is Enter and Shift is not pressed
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent the default Enter behavior (e.g., new line in a textarea)
      handleSubmit();
      resetTextareaHeight();
    }
  };
  const handleInputChange = (e: any) => {
    setChatInput(e.target.value);
    autoResizeTextarea(e.target);
  };
  const autoResizeTextarea = (element: any) => {
    element.style.height = "auto";
    element.style.height = Math.min(element.scrollHeight, 200) + "px";
  };

  const resetTextareaHeight = () => {
    // Reset the textarea height to its default value
    const textarea = document.querySelector(
      ".chat-input-text"
    ) as HTMLTextAreaElement | null;
    if (textarea) {
      textarea.style.height = "auto";
    }
  };

  const handleCallBack = () => {};

  return (
    <>
      {isModalOpen && (
        <div className="modal-con" onClick={closeModal}>
          <ConfirmationModal handleNo={closeModal} handleYes={closeModal} />
        </div>
      )}
      <div className={`chat-con`}>
        <img src={CGBackground} className="CG-watermark" />
        <div className="chat-subnav-con">
          <div className="h2-white">Trading Strategy</div>
          <div className="chat-option-icon-con">
            <OptionIcon
              onClick={() => {
                setIsOptionMenuClosed(!isOptionMenuClosed);
              }}
            />
          </div>
        </div>
        <div
          className={
            mobileMode ? `mobile-chat-content-con` : `chat-contenet-con`
          }
        >
          <div className="chat-top-con">
            {showWelcome && (
              <div className="welcome-con">
                <div className="h1-white" style={{ textAlign: "center" }}>
                  Welcome to CG AI
                </div>
                <div className="h1-subtext mt-12">
                  Your Personal Trading Assistant, curated to help in every
                  trader’s journey
                </div>
                <div className="chat-button-con mt-40">
                  <div
                    className={
                      tradingBehaviour === "safe"
                        ? "primary-btn"
                        : "secondary-btn"
                    }
                    onClick={() => setTradingBehaviour("safe")}
                  >
                    Safe
                  </div>
                  <div
                    className={
                      tradingBehaviour === "balance"
                        ? "primary-btn"
                        : "secondary-btn"
                    }
                    onClick={() => setTradingBehaviour("balance")}
                  >
                    Balanced
                  </div>
                  <div
                    className={
                      tradingBehaviour === "aggresive"
                        ? "primary-btn"
                        : "secondary-btn"
                    }
                    onClick={() => setTradingBehaviour("aggresive")}
                  >
                    Aggresive
                  </div>
                </div>
              </div>
            )}
            <div className="chat-qna-con">
              {currentQNA.map((qna: any) => (
                <ChatQNA
                  question={qna?.ques}
                  answer={qna?.ans}
                  displayResponseProp={displayResponse}
                  onGoingStream={onGoingStream}
                />
              ))}
              {isLoading && (
                <div className="answer-con">
                  <div className="cg-answers">
                    <div className="cg-logo">
                      <CGAIIcon />
                    </div>
                    <div className="answer-section">
                      <div className="user-name">CG AI</div>
                      <br />
                      <div className="answer">
                        <div className="loading"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div ref={ref} />
            {showWelcome && (
              <div className="chat-bottom-card-con">
                {chatCardOptions.map((cardOption: any, index: number) => (
                  <>
                    <div onClick={() => handleCardClick(cardOption)}>
                      <ChatCard
                        title={cardOption.title}
                        subtitle={cardOption.subtitle}
                        key={index}
                      />
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
          <div className="chat-bottom-con mt-8">
            {showStopResponding && (
              <div
                className="stop-button-con mt-8"
                onClick={() => setShowStopResponding(false)}
              >
                <StopIcon />
                <div className="h4-white">Stop Responding</div>
              </div>
            )}
            <div
              className="chat-input-con mt-8"
              onClick={() => setInputActive(true)}
            >
              <label style={{ width: "100%" }}>
                {!inputActive && (
                  <div className="h3-gray">Write your prompt here</div>
                )}
                {inputActive && (
                  <textarea
                    className="chat-input-text"
                    name="userQuestion"
                    value={chatInput}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    rows={1}
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                    }}
                  />
                )}
              </label>
              <PlayIcon style={{ cursor: "pointer" }} onClick={handleSubmit} />
            </div>
          </div>
        </div>
        {!isOptionMenuClosed && (
          <div className="normal-menu-con" onClick={openModal}>
            <OptionMenu menuOptions={menuOptions} callBack={handleCallBack} />
          </div>
        )}
      </div>
    </>
  );
};

export default ChatHome;
