import { useContext, useEffect, useState } from "react";
import { ApplicationCtx } from "../../../contexts/ApplicationCtx";
import TradingLogo from "../../../assets/icons/image_374.svg";
import CGLogo from "../../../assets/icons/image_369.svg";

import "./style.scss";
import {
  BLACK_CARD_OPTIONS,
  QUICK_ACTIONS_OPTIONS,
  TRADING_STRATEGIES_OPTIONS,
  TRADING_STRATEGIES_BLACK_OPTIONS,
  GRAY_CARD_OPTIONS,
} from "./constants";
import LinkButton from "../../shared/buttons/LinkButton";
import PromptGenerator from "../../shared/PromptGenerator/PromptGenerator";
import { _generatePrompt } from "../../../network/chat";
import { log } from "console";

const Explore = () => {
  const { setIsSideNavOpen, setUrl, setIsMobileNavBarActive } =
    useContext(ApplicationCtx);

  useEffect(() => {
    let url = window.location.href;
    setUrl(url);
    setIsSideNavOpen(true);
    setIsMobileNavBarActive(true);
  }, []);

  return (
    <>
      <div className="explore-con">
        <div className="h1-white">Explore</div>
        <div className="h2-white mt-40">Getting Started</div>
        <div className="explore-black-card-con mt-40 hidden">
          {BLACK_CARD_OPTIONS.map((option: any) => (
            <div
              className={`black-card-con ${option.active ? "" : "gray-scale"}`}
            >
              <div className="top-logo">
                <img src={CGLogo} className="image-top floating" />
              </div>
              <div className="rotate-on-hover">
                <div className="h4-blue">{option.title}</div>
                <div className="h3-white mt-8">{option.subtitle}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="h2-white mt-40">Quick Actions</div>
        <div className="action-card-con mt-20 hidden">
          {QUICK_ACTIONS_OPTIONS.map((option: any) => (
            <div
              className={`coloured-card-con ${option.background}`}
              style={{ position: "relative" }}
            >
              <div className="title-con">
                <div className="h3-white">{option.title}</div>
              </div>
              <div
                className="h4-white mt-40"
                style={{ position: "absolute", bottom: "16px", left: "32px" }}
              >
                {option.subtitle}
              </div>
            </div>
          ))}
        </div>
        <div className="df-sb mt-40">
          {GRAY_CARD_OPTIONS.map((option: any) => (
            <div className="gray-link-card-con">
              <div className="h3-white">{option.title}</div>
              <div className="h2-subtext mt-32">{option.subtitle}</div>
              <div className="mt-20 get-started">
                <div className="get-started-button">{option.button}</div>
                <div className="cg-logo-con floating">{option.image}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-40">
          <PromptGenerator />
        </div>
        <div className="trading-strategies">
          <div className="h2-white mt-40">Smart Trading Strategies</div>
          <div className="action-card-con mt-20 hidden">
            {TRADING_STRATEGIES_OPTIONS.map((option: any) => (
              <div className={`coloured-card-con ${option.background}`}>
                <div className="h2-white">{option.title}</div>
                <div className="trading-image floating">{option.image}</div>
              </div>
            ))}
          </div>
          <div className="h2-white mt-40">
            Quality Prompts, Curated by Editors
          </div>
          <div className="trading-strategies-gray-card-con mt-20">
            {TRADING_STRATEGIES_BLACK_OPTIONS.map((option: any) => (
              <>
                <div className="h2-white gray-link-card-con">
                  <div className="trading-type-con">{option.type}</div>
                  <div className="mt-20">{option.title}</div>
                  <div className="mt-20">{option.button}</div>
                </div>
              </>
            ))}
          </div>
        </div>

        <div className="feedback-card mt-40">
          <div className="h2-white">
            Suggest a new feature or provide feedback to us
          </div>
          <div className="btn-section mt-40">
            <button className="button-28">Review Us</button>
            <button className="button-28">Help us improve</button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Explore;
