import "./style.scss";
import { ReactComponent as BlueRightArrowIcon } from "../../../assets/icons/right-arrow-icon.svg";
import { ReactComponent as WhiteRightArrowIcon } from "../../../assets/icons/uil_arrow-right.svg";

const LinkButton = ({ title, type }: { title: string; type: string }) => {
  return (
    <>
      <div className="link-button-con">
        <div className={type}>{title}</div>
        {type === "h3-blue" ? <BlueRightArrowIcon /> : <WhiteRightArrowIcon />}
      </div>
    </>
  );
};

export default LinkButton;
