import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  mobileBottomMenuOptions,
  sidepanelMenuOptions,
} from "../../sidepanel/constants";
import "./style.scss";
import { ApplicationCtx } from "../../../../contexts/ApplicationCtx";

const MobileBottomPanel = () => {
  const navigate = useNavigate();

  const [selectedMenu, setSelectedMenu] = useState(null);
  const [menuOptioin, setMenuOption] = useState(mobileBottomMenuOptions);
  const { url } = useContext(ApplicationCtx);

  useEffect(() => {
    setMenuOption((prevState: any) => {
      return prevState.map((option: any) => {
        if (url.indexOf(option.path) != -1) {
          return {
            ...option,
            active: true,
          };
        }
        return {
          ...option,
          active: false,
        };
      });
    });
  }, [url]);

  const doNavigation = (menu: any) => {
    navigate(menu.path);
    setSelectedMenu(menu);
  };

  return (
    <>
      <div className="mobile-bottom-container">
        {menuOptioin.map((menu: any, index: any) => (
          <div
            key={index}
            className={`mobile-menu-con ${menu.active ? "" : "gray-scale"}`}
            onClick={() => doNavigation(menu)}
          >
            <div className="menu-icon-con">{menu.icon}</div>
            <div className="h4-white">{menu.title}</div>
            {menu.active ? (
              <div className="active-menu"></div>
            ) : (
              <div className="inactive-menu"></div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default MobileBottomPanel;
