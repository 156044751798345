import { useContext, useEffect, useState } from "react";
import MainIcon from "../../../assets/icons/main-icon.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user-icon.svg";
import "./style.scss";
import { ApplicationCtx } from "../../../contexts/ApplicationCtx";
import OptionMenu from "../../shared/optionMenu/OptionMenu";
import { useAuth0 } from "@auth0/auth0-react";
const Navbar = () => {
  const { cgUser } = useContext(ApplicationCtx);
  const [isUserClicked, setIsUserClicked] = useState(false);
  const { logout, getAccessTokenSilently } = useAuth0();

  const handleCallBack = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <>
      <div className="navbar-con">
        <div className="cg-ai-icon">
          <img src={MainIcon} />
        </div>
        {cgUser && (
          <>
            {cgUser?.picture ? (
              <div
                className="cg-user-icon"
                onClick={() => setIsUserClicked(!isUserClicked)}
              >
                <img width="40px" height="40px" src={cgUser?.picture} />
              </div>
            ) : (
              <div
                className="cg-user-icon"
                onClick={() => setIsUserClicked(!isUserClicked)}
              >
                <div className="user-name-icon">
                  <div className="h2-white">
                    {`${cgUser?.given_name.substring(
                      0,
                      1
                    )}${cgUser?.family_name.substring(0, 1)}`}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {isUserClicked && (
          <div className="user-menu-con">
            <OptionMenu
              menuOptions={[{ title: "Log Out" }]}
              callBack={handleCallBack}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
