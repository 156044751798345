import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import "./style.scss";
import MobileSidePanel from "../mobileSidePanel/MobileSidePanel";
import NotificationBell from "../../../../assets/mobile-icons/bell.svg";
import MenuIcon from "../../../../assets/mobile-icons/eva_menu-outline (1).svg";
import CrossIcon from "../../../../assets/mobile-icons/icons8-cross-28.png";
import CGIcon from "../../../../assets/icons/cg-white.svg"

const MobileNavBar = () => {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

  
  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };
  

  return (
    <>
      <div className="mobile-navbar-con">
        <div onClick={toggleSidePanel}>
          {isSidePanelOpen ? <img src={CrossIcon} /> : <img src={MenuIcon} />}
          {isSidePanelOpen && (
          <div className={`mobile-sidepanel ${isSidePanelOpen ? "open" : ""}`}>
            <MobileSidePanel />
          </div>
        )}
        </div>{" "}
        
        <div className="nav-title h2-white">
          <img src={CGIcon}/>
          Cryptograd</div>
        <div className="notification-bell-con">
          <img src={NotificationBell} />
        </div>
      </div>
    </>
  );
};

export default MobileNavBar;
