export const userList = [
  {
    rank: 1,
    name: "Jay Quinn",
    membership: "Platinum",
    credits: 9000,
  },
  {
    rank: 1,
    name: "Simon Waters",
    membership: "Platinum",
    credits: 9000,
  },
  {
    rank: 1,
    name: "Karan Kapoor",
    membership: "Platinum",
    credits: 9000,
  },
];