import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { routes } from "./Routes";
import Navbar from "./components/layouts/navbar/Navbar";
import Landing from "./components/pages/landing/Landing";
import Sidepanel from "./components/layouts/sidepanel/Sidepanel";
import "./styles/main.scss";
import "./styles/animation.scss";
import { ApplicationCtx } from "./contexts/ApplicationCtx";
import { useAuth0 } from "@auth0/auth0-react";
import { __setItemInLocalStorage } from "./utils/Utility";
import { _getUserProfile } from "./network/user";
import MobileSidepanel from "./components/layouts/mobile/mobileBottomPanel/MobileBottomPanel";
import MobileNavBar from "./components/layouts/mobile/mobileNavBar/MobileNavBar";
import MobileBottomPanel from "./components/layouts/mobile/mobileBottomPanel/MobileBottomPanel";

function App() {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [showStopResponding, setShowStopResponding] = useState(false);
  const [url, setUrl] = useState<any>("");
  const [cgUser, setCgUser] = useState<any>();
  const [isMobileNavBarActive, setIsMobileNavBarActive] = useState(false);
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      setCgUser(user);
      getAccesToken();
    }
  }, [isAuthenticated]);

  const getAccesToken = async () => {
    await getAccessTokenSilently({
      authorizationParams: {
        audience: "https://api.cryptograd.ai",
      },
    })
      .then((res: any) => {
        __setItemInLocalStorage("_token", res);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const [mobileMode, setMobileMode] = useState(window.innerWidth < 768);

  const observer = new IntersectionObserver((enteries) => {
    enteries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("show");
      } else {
        entry.target.classList.remove("show");
      }
    });
  });
  const hiddenElements = document.querySelectorAll(".hidden");
  hiddenElements.forEach((e) => observer.observe(e));

  useEffect(() => {
    console.log({ mobileMode, isSideNavOpen });
  }, [mobileMode]);

  return (
    <ApplicationCtx.Provider
      value={{
        isSideNavOpen: isSideNavOpen,
        setIsSideNavOpen: setIsSideNavOpen,
        showStopResponding: showStopResponding,
        setShowStopResponding: setShowStopResponding,
        url: url,
        setUrl: setUrl,
        cgUser: cgUser,
        setCgUser: setCgUser,
        mobileMode: mobileMode,
        setMobileMode: setMobileMode,
        isMobileNavBarActive: isMobileNavBarActive,
        setIsMobileNavBarActive: setIsMobileNavBarActive,
      }}
    >
      <div className="App">
        <BrowserRouter>
          {mobileMode ? (
            isMobileNavBarActive && (
              <div className="nav-con">
                <MobileNavBar />
              </div>
            )
          ) : (
            <div className="nav-con">
              <Navbar />
            </div>
          )}
          <div className={mobileMode ? `mobile-main-con` : `main-con`}>
            {isSideNavOpen && !mobileMode && (
              <div className="sidepanel-con">
                <Sidepanel />
              </div>
            )}
            <div
              className={mobileMode ? `mobile-page-view-con` : `page-view-con`}
            >
              <Routes>
                {routes.map(({ path, component }, index) => (
                  <Route path={path} element={component} key={index} />
                ))}
              </Routes>
            </div>
            {isSideNavOpen && mobileMode && (
              <div className="mobile-bottompanel-con">
                <MobileBottomPanel />
              </div>
            )}
          </div>
        </BrowserRouter>
      </div>
    </ApplicationCtx.Provider>
  );
}

export default App;
