import Icon from "../../shared/icon/Icon";
import { ReactComponent as ChatIcon } from "../../../assets/icons/chat-icon.svg";
import { ReactComponent as ExploreIcon } from "../../../assets/icons/explore-icon.svg";
import { ReactComponent as TaskIcon } from "../../../assets/icons/task-icon.svg";
import { ReactComponent as CommunityIcon } from "../../../assets/icons/community-icon.svg";
import { ReactComponent as HistoryIcon } from "../../../assets/icons/history-icon.svg";
import { ReactComponent as ReferIcon } from "../../../assets/icons/Vector.svg";
import { ReactComponent as LeaderBoardIcon } from "../../../assets/icons/Mask group.svg";
import { ReactComponent as UserIcon } from "../../../assets/mobile-icons/uil_user-circle.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/mobile-icons/uil_bell.svg";
import { ReactComponent as SubscriptionIcon } from "../../../assets/mobile-icons/uil_money-bill.svg";
import { ReactComponent as BlogIcon } from "../../../assets/mobile-icons/uil_align-left.svg";
import { ReactComponent as ContactIcon } from "../../../assets/mobile-icons/headset1.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/mobile-icons/sign-out1.svg";

export const sidepanelMenuOptions = [
  {
    icon: <ChatIcon />,
    title: "Chat",
    key: "chat",
    path: "/chats",
    active: false,
  },
  {
    icon: <ExploreIcon />,
    title: "Explore",
    key: "explore",
    path: "/explore",
    active: false,
  },
  {
    icon: <HistoryIcon />,
    title: "History",
    key: "history",
    path: "/ai-history",
    active: false,
  },
  {
    icon: <ReferIcon />,
    title: "Refer & Earn",
    key: "refer",
    path: "/refer",
    active: false,
  },
  {
    icon: <LeaderBoardIcon />,
    title: "Leaderboard",
    key: "leaderboard",
    path: "/leaderboard",
    active: false,
  },
];

export const mobileBottomMenuOptions = [
  {
    icon: <ChatIcon />,
    title: "Chat",
    key: "chat",
    path: "/chats",
    active: false,
  },
  {
    icon: <ExploreIcon />,
    title: "Explore",
    key: "explore",
    path: "/explore",
    active: false,
  },
  {
    icon: <HistoryIcon />,
    title: "History",
    key: "history",
    path: "/ai-history",
    active: false,
  },
];

export const mobileSidePanelMenuOptions = [
  {
    icon: <ChatIcon />,
    title: "Chat",
    key: "chat",
    path: "/chats",
    active: false,
  },
  {
    icon: <ExploreIcon />,
    title: "Explore",
    key: "explore",
    path: "/explore",
    active: false,
  },
  {
    icon: <HistoryIcon />,
    title: "History",
    key: "history",
    path: "/ai-history",
    active: false,
  },
  {
    icon: <SubscriptionIcon />,
    title: "Subscription",
    key: "history",
    path: "/ai-history",
    active: false,
  },
  {
    icon: <BlogIcon />,
    title: "Blog",
    key: "history",
    path: "/ai-history",
    active: false,
  },
  {
    icon: <ReferIcon />,
    title: "Refer & Earn",
    key: "refer",
    path: "/refer",
    active: false,
  },
  {
    icon: <ContactIcon />,
    title: "Contact Us",
    key: "history",
    path: "/ai-history",
    active: false,
  },
  {
    icon: <LogoutIcon />,
    title: "Log Out",
    key: "logout",
    path: "/logout",
    active: false,
  },
];
